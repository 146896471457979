<template>
  <div class="home-page">
    <div style="height: 3.3333rem">
      <el-carousel height="3.3333rem" v-if="bannerData[0]!=null" :autoplay="true" class="home-banner">
        <el-carousel-item v-for="item in bannerData" :key="item.imgId">
          <img @click="cherryClick(item.imgId)" style="height: 3.3333rem; width: 100%" :src="item.imgUrl" :alt="item.text" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="home-card m-t-100">
      <div class="home-card-img office-img left-to-right"></div>
      <div class="home-card-txt right-to-left">
        <div class="card-txt-title">传信办公多端适配</div>
        <div class="card-txt">
          <div>即时通信是一种在线互动式通信方式，能够让用户在任何时间、任何地点通过网络进行实时交流。</div>
          <div>1. 即时性强：即时通信可以实现即时、实时的通信，方便快捷。</div>
          <div>2. 便捷性高：用户不需要额外的设备或软件，只需通过网络即可使用即时通信功能。</div>
          <div>3. 可以传送多种媒体：即时通信可以传送文字、图片、音频、视频等多种媒体，能够满足不同交流需求。</div>
          <div>4. 节省时间和成本：即时通信可以节省传统通信方式的时间和成本，如打电话或发传真。</div>
        </div>
      </div>
    </div>
    <div class="home-card m-t-100">
      <div class="home-card-txt applet-txt">
        <div class="card-txt-title applet-txt delay-animation  left-to-right">丰富的小程序应用</div>
        <div class="card-txt delay-animation left-to-right">
          <div>我们开发了多个小程序：考勤打卡，统一待办，流程审批，工作圈，日程…</div>
          <div>1. 考勤打卡：随时随地查看出勤数据，即时发现优秀团队和个人 <br>打开手机自动打卡，告别排队，员工不再忘打卡</div>
          <div>2. 统一待办：任何事都能快速记录成待办。设置好截止时间，到点自动提醒，不用担心遗漏任何事情。</div>
          <div>
            3.流程审批：通过表单关联，可以在流程表单中引用数据表单，让数据更准确，便于业务统计和分析 <br>多维度、多指标智能分析组织流程数据，助力组织提升业务效率<br>每一步的流程审批人明确，层级审批，清晰明了。
          </div>
        </div>
      </div>
      <div class="applet-img delay-animation right-to-left"></div>
    </div>

    <div class="about-us m-t-100 flex-row-ceterr">
      <h3 class="second-title">关于我们</h3>
      <div class="about-us-conent">
        <div class="text-Ddsc conent-desc">
          北京领雁科技股份有限公司，定位于做客户深度经营的推动者，致力于为金融机构提供领先的产品和专业化服务。公司成立于2001年，总部位于北京，在上海、广州、成都、郑州、武汉等地设有5大软件中心，现有员工1800余人。领雁科技连续多年在中国银行业
          IT
          解决方案市场排名领先，为⾦融机构提供包括：移动⾦融、智慧营销、智能⻛控、产业⾦融、智能⽀付等解决⽅案，其中，在移动⾦融、智慧营销、智能⻛控等细分领域居于⾏业领先位置，在产业⾦融、智能⽀付等领域有较深布局。目前已累计为260余家金融机构提供优质的金融IT服务和解决方案。
        </div>
        <div class="about-us-img">
          <img style=" width: 100%;height: 2.8333rem;border-radius: 0.0694rem;" v-lazy="aboutUsImg" alt="领雁科技连续多年在中国银行业 IT 解决方案市场排名领先" srcset="">
        </div>
      </div>
    </div>
    <div class="news m-t-100">
      <h3 class="second-title">新闻资讯</h3>
      <div class="new-message-box">
        <div class="news-message">
          <div class="message-item" v-for="item in newMessage.slice(0,3)" :key="item.msgId">
            <div v-if="item.msgId == '111'" class="new-message-img"></div>
            <div v-if="item.msgId == '222'" class="new-message-img message-img2"></div>
            <div v-if="item.msgId == '333'" class="new-message-img message-img3"></div>
            <div class="msg-time">{{ item.timer }}</div>
            <div class="msg-title">{{ item.title }}</div>
            <div class="msg-time msg-desc">{{ item.desc }}</div>
            <!-- <div class="msg-all"><div class="read-all"></div></div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="custom-tip m-t-100">
      <h3 class="second-title">客户案例</h3>
      <div class="text-Ddsc custom-desc m-b-45">
        移动平台能够规范和指导企业移动应用项目的开发与应用管理，为企业提供管理、运维、服务等全方位的移动信息化方案，
        整个方案覆盖B2B、B2C需求，为企业提供移动信息化的全面支撑。
      </div>
     
      <div  class="swiper-container  custom-box ">
        <div class="swiper-wrapper">
          <div class="swiper-slide case-item" >
              <div class="case-item-msg case-img1">
                <div>某股份制银行</div>
                <div>数字化协作平台项目</div>
              </div>
              <div class="case-item-desc">
                <div class="item-left-desc bor-r-1">
                  <div>80W+</div>
                  <div class="mini-desc">消息总数</div>
                </div>
                <div class="item-left-desc">
                  <div>2000+</div>
                  <div class="mini-desc">工作群</div>
                </div>
              </div>
          </div>
          <div class="swiper-slide case-item">
              <div class="case-item-msg case-img2">
                <div>某股份制银行</div>
                <div>企业手机银行项目</div>
              </div>
              <div class="case-item-desc flex-column-center">
                <div>跨产品 跨银行 跨行业</div>
                <div class="mini-desc">多系统、多平台的对接打通与合作协调</div>
              </div>
          </div>
          <div class="swiper-slide case-item ">
            <div class="case-item-msg case-img3">
              <div>某股份制银行</div>
              <div>电子渠道应用系统项目</div>
            </div>
            <div class="case-item-desc flex-column-center">
              <div class="last-desc">智能化、场景化、数字化</div>
            </div>
          </div>
          
        </div>
      </div>

    </div>
    <div class="success-tip m-t-100 m-b-45 ">
      <div style="margin-top:.625rem;" class="second-title">领雁优势</div>
      <div class="company-advantage">
          <div class="advantage-item" v-for="(item ,index) in advantageItem.slice(0,4)" :key="item.imgUrl">
              <div class='advantage-item-img'>
                <img v-if="index == 0" class="item-img" v-lazy="item.imgUrl" alt="20年以上专注服务金融企业">
                <img v-if="index == 1" class="item-img1" v-lazy="item.imgUrl" alt="60以上金融行业客户">
                <img v-if="index == 2" class="item-img2" v-lazy="item.imgUrl" alt="90%人员都是技术专业力量">
                <img v-if="index == 3" class="item-img3" v-lazy="item.imgUrl" alt="1000多家科技金融案例">
              </div>
              <div class="advantage-item-title"> {{ item.title }}</div>
              <div class="advantage-item-desc">{{ item.desc }}</div>
          </div>
      </div>
      <div class="company-advantage">
          <div class="advantage-item" v-for="(item , index) in advantageItem.slice(4,8)" :key="item.imgUrl">
              <div class="advantage-item-img" :class="[index == 3 ? 'mt35' : '']">
                <img v-if="index == 0" class="item-img4" v-lazy="item.imgUrl" alt="ACE三大技术平台">
                <img v-if="index == 1" class="item-img5" v-lazy="item.imgUrl" alt="70多个产品解决方案">
                <img v-if="index == 2" class="item-img6" v-lazy="item.imgUrl" alt="3大大核心业务线">
                <img v-if="index == 3" class="item-img7" v-lazy="item.imgUrl" alt="130多个知识产权">
                <!-- <img  class="advantage-item-img" :src="item.imgUrl" alt=""> -->
              </div>
              <div class="advantage-item-title"> {{ item.title }}</div>
              <div class="advantage-item-desc">{{ item.desc }}</div>
          </div>
      </div>
    </div>
    <div class="foot-text flex-column-center">
      <div class="foot-text-one">选择我们, 实现更高效的办公</div>
      <div>
        <button class="foot-text-button" @click="cherryClick('1')">移动平台试用</button>
      </div>
    </div>

    <!-- 申请试用弹窗 -->
    <!--  <apply-use v-mdel="applyUseVisible" :visible='applyUseVisible' @close="closedialog()" /> -->
    
      <div v-if="isShow" class="red-popover" @click="closePopover">
        <div class="popover"></div>
        <img class="redpack" src="../../assets/home/red-pack@2x.png" alt="申请试用" />
      </div>
    

      <div v-if="centerDialogVisible">
        <div class="popover" id="apply-content"></div>
        <div id="applyUse">
          <div class="close-icon" @click="closeDailog">
            <img src="../../assets/home/close@2x.png" />
          </div>
          <img class='header-img' src="../../assets/home/form@2x.png" />
          <addCustomer @closeApplyDailog="closeDailog"> </addCustomer>
        </div>
      </div>
  </div>
</template>

<script setup lang="ts">
import aboutUsImg from "../../assets/home/about-us@2x.png";
import Swiper from "swiper";
import AddCustomer from "../ecologycooperation/components/addCustomer.vue";
import { newMessage, bannerData, advantageItem } from "../image";
import { ref, reactive, toRefs, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
const router = useRouter()
const isShow = ref<boolean>();
const showContent = ref<boolean>();
const imgNumber = ref<number>(0);

// 红包弹框的显示和隐藏
const closePopover = () => {
  isShow.value = false;
  window.sessionStorage.setItem("isclick", "0");
};
const swiperRef = ref<Swiper | null>(null);
// 判断是否显示红包弹框
onMounted(() => {
  if (window.sessionStorage.getItem("isclick") == null) {
    isShow.value = false;//暂时不要弹框功能
  } else {
    isShow.value = false;
  }
  swiperRef.value = new Swiper(".swiper-container", {
    slidesPerView: "auto",
    // spaceBetween: 30,
    // scrollbar: {
    //   el: '.swiper-scrollbar',
    //   hide: true,
    // },
  });
});
// const imgLoad = () =>{
//   imgNumber.value++
//   if( imgNumber.value == 3){
//     showContent.value = true
//   }
// }
// 表单弹框显示
const centerDialogVisible = ref<boolean>(false);
const cherryClick = (id: string) => {
  console.log('ididididid',id)
  if (id === "banner1" || id === "1") {
    centerDialogVisible.value = true;
  }
  if (id === "banner2") {
     router.push({ path: "nowmessage" })
  }
  
};
// 关闭表单弹窗
const closeDailog = () => {
  centerDialogVisible.value = false;
};
</script>
<style></style>
<style lang="scss" scoped>
@import "../../../node_modules/swiper/swiper-bundle.css";
@import "./index.scss";
.home-banner :deep(.el-carousel__indicators button) {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #fff;
}
.home-banner :deep(.el-carousel__indicators button.is-active) {
  width: 20px !important;
  height: 10px !important;
  border-radius: 5px !important;
  background-color: yellow !important;
}
.home-banner :deep(.el-carousel__indicators button.is-active) span {
  display: none;
}


</style>
